<template>
  <div class="homepage-3">
    <ScrollupSection />
    <div class="main overflow-hidden">
      <HeaderSection />
      <BreadcrumbSection :titulo="titulo" :descripcion="descripcion" />
      <section class="section service-area">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <horizontal-stepper
                :steps="demoSteps"
                @completed-step="completeStep"
                @active-step="isStepActive"
                @stepper-finished="alert"
                class="m-5"
                locale="es"
              >
              </horizontal-stepper>
            </div>
          </div>
        </div>
      </section>
      <FooterSection/>
      <ModalSearchSection />
      <ModalRegistro/>
      <ModalMenuSection />
      <ModalTerms/>
    </div>
  </div>

</template>

<script>
import {analytics, auth} from "../../../firebase";
import { mapActions, mapMutations } from "vuex";

import ScrollupSection from "../../Scrollup/Scrollup";
import HeaderSection from "../../Header/HeaderOne";
import BreadcrumbSection from "../../Breadcrumb/Services";
import FooterSection from "../../Footer/FooterOne";

import ModalSearchSection from "../../Modal/ModalSearch/ModalSearch";
import ModalRegistro from "../../Modal/ModalSearch/ModalRegistro";
import ModalMenuSection from "../../Modal/ModalMenu/ModalMenu";
import ModalTerms from '../../Modal/ModalTerms/ModalTerms';

import HorizontalStepper from "vue-stepper";
import "vue-select/dist/vue-select.css";
import GeneralFormRepresentate from "../../../components/forms/GeneralesRepresentante";
import Generals from "../../../components/forms/recursoRAP2/Generals";
import Complement from "../../../components/forms/recursoRAP2/Complement";
import Hechos from "../../../components/forms/recursoRAP2/Hechos";
import Pruebas from "../../../components/forms/recursoRAP2/Pruebas";
//import MotivosAgravio from "../../../components/forms/recursoRAP/MotivosAgravio";

export default {
  name: "recursoRAP2",
  metaInfo: {
    title: "Electoral Tech & Legan | Recurso de Apelación",
    description:
      "Crea y genera de manera simple tu formato de Recurso de Apelación",
  },
  components: {
    ScrollupSection,
    HeaderSection,
    BreadcrumbSection,
    FooterSection,
    ModalSearchSection,
    ModalMenuSection,
    ModalRegistro,
    ModalTerms,
    HorizontalStepper,
  },
  mounted() {
    analytics.logEvent("formatoComenzado", { name: "recursoRAP2" });
    window.$("#modalTerminos").modal("show");
    this.setTipoFormato(15);
    // window.$("#ModalCaptcha").modal("show");
  },
  data() {
    return {
      titulo: "Apelación contra emplazamiento (RAP)",
      tituloFormato: "Apelación contra emplazamiento (RAP)",
      tituloArchivo: "apelacion-RAP.pdf",
      descripcion:
        "¿Te emplazaron a un procedimiento de fiscalización en materia electoral sin las garantías del debido proceso? Elabora tu propio recurso en línea y defiende tus derechos de manera pronta, completa y eficaz.",
      urlProd:
        "https://us-central1-electoraltech-d9cec.cloudfunctions.net/webApi/generarRecursoRAP2",
      urlLocal:
        "http://localhost:5001/electoraltech-d9cec/us-central1/webApi/generarRecursoRAP2",
      demoSteps: [
        {
          icon: "person",
          name: "first",
          title: "Datos del representante",
          // subtitle: "Subtitle sample",
          component: GeneralFormRepresentate,
          completed: true,
        },
        {
          icon: "person",
          name: "second",
          title: "Datos del accionante",
          // subtitle: "Subtitle sample",
          component: Generals,
          completed: false, 
        },
        /*
        {
          icon: "list",
          name: "fourth",
          title: "Datos oficios impugnados",
          // subtitle: "Subtitle sample",
          component: MotivosAgravio,
          completed: false,
        },
        */
        {
          icon: "announcement",
          name: "third",
          title: "Informacion denunciada",
          // subtitle: "Subtitle sample",
          component: Complement,
          completed: false,
        },
        {
          icon: "announcement",
          name: "third",
          title: "Actos denunciados",
          // subtitle: "Subtitle sample",
          component: Hechos,
          completed: false,
        },
        {
          icon: "speaker_notes",
          name: "fifth",
          title: "Pruebas",
          // subtitle: "Subtitle sample",
          component: Pruebas,
          completed: false,
        }
      ],
    };
  },
   computed: {
    url() {
      return process.env.NODE_ENV == "development"
        ? this.urlLocal
        : this.urlProd;
    },
  },
  methods: {
    ...mapMutations('generals', ['setTipoFormato']),
    ...mapActions('contenido', ['guardarFormato']),
    completeStep(payload) {
      this.demoSteps.forEach((step) => {
        if (step.name === payload.name) {
          step.completed = true;
        }
      });
    },
    errorMessage(error) {
      this.$swal({
        icon: "error",
        title: error,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        showCancelButton: true,
      });
    },
    // Executed when @active-step event is triggered
    isStepActive(payload) {
      this.demoSteps.forEach((step) => {
        if (step.name === payload.name) {
          if (step.completed === true) {
            step.completed = false;
          }
        }
      });
    },
    async alert() {
      // window.$("#ModalCaptcha").modal("show");}
      if ( auth.currentUser != null ) {
        try{
          this.$swal({
            icon: "info",
            title: "Guardando comentario",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            timerProgressBar: true,
            showCancelButton: true,
            didOpen: () => this.$swal.showLoading(),
          });
          await this.guardarFormato({nombreFormato: this.tituloFormato, tipoFormato: 15});
          this.$swal({
            icon: "success",
            title: "Formato De: Apelación contra emplazamiento (RAP) Generado",
            text: "Para descargar el formato, debes ir a tu perfil, revisar la información de pago, y subir tu comprobante de pago",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              console.log('confirmo')
              this.$router.push({ name: "perfil" });
            } else if (result.isDenied) {
              console.log('no confirmo')
            }
          });
        }catch (e) {
          console.warn(e);
          this.errorMessage('Revise su conexión a internet y pruebe más tarde');
        }
      } else {
        const result = await this.$swal({
          icon: "info",
          title: "Registrate o Inicia sesión!",
          text: "Crea tu cuenta o inicia sesión para descargar este formato en tu perfil",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showCancelButton: true,
          confirmButtonText: 'Crear cuenta ahora',
        });
        if (result.isConfirmed) {
          window.$("#registro").modal('show');
        } else if (result.isDenied) {
          this.$router.push({ name: "inicio" });
          console.log('no confirmo')
        }
      }
    },
  },
};
</script>

<style>
.stepper-box .content {
  overflow: visible !important;
}
</style>